class CookiebotDialogHandler {
  static RETRY_TIMEOUT = 100;
  static RETRY_MAX_ATTEMPTS = 50; // 5s

  constructor() {
    this.attempts = 0;
    this.$dialogInstance = null;
  }

  reset() {
    this.attempts = 0;
  }

  static onCookiebotOnDialogDisplay() {
    window.cookiebotDialogHandler.show(true);
  }

  setDialogInstance($instance) {
    this.$dialogInstance = $instance;
  }

  isDialogInstanceSet() {
    return this.$dialogInstance != null;
  }

  _show() {
    if (this.isDialogInstanceSet()) {
      this.$dialogInstance.show();
      return true;
    }
    this.attempts++;
    return false;
  }

  show(reset = false) {
    if (reset === true) {
      this.reset();
    }

    const run = this._show();
    if (run === true) {
      return;
    }
    if (this.attempts >= CookiebotDialogHandler.RETRY_MAX_ATTEMPTS) {
      CookiebotDialogHandler.captureMessage('Cookiebot dialog: max attempts passed without dialog instance initialization', {}, 'error');
      return;
    }

    setTimeout(() => {
      window.cookiebotDialogHandler.show();
    }, CookiebotDialogHandler.RETRY_TIMEOUT);
  }

  static captureMessage(message, extra = null, level = 'info') {
    if (typeof (window.Sentry) === 'undefined') {
      return;
    }

    setTimeout(() => {
      window.Sentry.configureScope((scope) => {
        scope.setLevel(level);

        if (extra !== null) {
          Object.keys(extra).forEach(key => {
            scope.setExtra(key, extra[key]);
          });
        }

        window.Sentry.captureMessage(message);
      });
    }, 0);
  }
}

window.cookiebotDialogHandler = new CookiebotDialogHandler();

window.addEventListener('CookiebotOnDialogDisplay', CookiebotDialogHandler.onCookiebotOnDialogDisplay, false);
